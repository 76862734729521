import React from 'react'
import { format, Formats } from '@toasttab/buffet-pui-date-utilities'
import cx from 'classnames'
import { TimeSlotButton } from '../TimeSlotButton/TimeSlotButton'
import type { Locale } from '@toasttab/buffet-pui-locale-utilities'
import type { Deposit } from '../../../api/availabilities/getAvailabilities'

type ServiceAreaGroupData = {
  name: string
  times: Set<string>
  guid: string
  depositAmounts: Map<string, Deposit | null> | undefined
}

export const formatServiceAreaGroups = (
  serviceAreaGroupNameToTimes: Map<string, Set<string>>,
  serviceAreaGroupNameToGuid: Map<string, string>,
  serviceAreaGroupNameToTimeToDepositAmount?: Map<
    string,
    Map<string, Deposit | null>
  >
): ServiceAreaGroupData[] => {
  return [...serviceAreaGroupNameToTimes.entries()].map(
    ([name, times]): ServiceAreaGroupData => ({
      name,
      times,
      guid: serviceAreaGroupNameToGuid.get(name) || '',
      depositAmounts: serviceAreaGroupNameToTimeToDepositAmount?.get(name)
    })
  )
}

type ServiceAreaGroupProps = {
  data: ServiceAreaGroupData
  index: number
  arrayLength: number
  timezone: string
  locale: Locale
  onTimeSlotSelect: (
    timeSlot: string,
    deposit: Deposit | null,
    groupGuid: string,
    groupName: string
  ) => void
}

export const ServiceAreaGroup = ({
  data,
  index,
  arrayLength,
  timezone,
  locale,
  onTimeSlotSelect
}: ServiceAreaGroupProps) => {
  const { name, times, guid, depositAmounts } = data

  return (
    <div className='flex flex-col gap-4'>
      <h2 className='font-semibold px-4 sm:px-0'>{name}</h2>
      <div
        data-testid='time-slots-container'
        className={cx(
          'flex gap-2 md:gap-4 border-b pb-4 whitespace-nowrap overflow-x-auto no-scrollbar sm:flex-wrap sm:overflow-x-visible sm:whitespace-normal',
          { 'mb-4': index < arrayLength - 1 }
        )}
      >
        {[...times].sort().map((timeSlot, timeSlotIndex, timeSlots) => {
          const deposit = depositAmounts?.get(timeSlot) || null
          const depositAmount = deposit?.strategy?.actualAmount

          const formattedTime = format(new Date(timeSlot), Formats.time.short, {
            locale,
            timeZone: timezone
          })

          return (
            <TimeSlotButton
              key={timeSlot}
              timeSlot={timeSlot}
              timeSlotIndex={timeSlotIndex}
              totalTimeSlots={timeSlots.length}
              formattedTime={formattedTime || ''}
              serviceAreaGroupName={name}
              depositAmount={depositAmount}
              onTimeSlotSelect={() =>
                onTimeSlotSelect(timeSlot, deposit, guid, name)
              }
            />
          )
        })}
      </div>
    </div>
  )
}

import React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import cx from 'classnames'
import { useFormatCurrency } from '../../../hooks/useFormatCurrency'
import { track } from '@toasttab/do-secundo-analytics'
import { AnalyticsEvents } from '../../../utils/analyticsEvents'

type TimeSlotButtonProps = {
  timeSlot: string
  timeSlotIndex: number
  totalTimeSlots: number
  formattedTime?: string
  serviceAreaGroupName: string
  depositAmount?: number
  onTimeSlotSelect: () => void
}

export const TimeSlotButton = ({
  timeSlot,
  timeSlotIndex,
  totalTimeSlots,
  formattedTime,
  serviceAreaGroupName,
  depositAmount,
  onTimeSlotSelect
}: TimeSlotButtonProps) => {
  const handleClick = () => {
    track(AnalyticsEvents.CLICK_AVAILABLE_TIME, {
      deposit: depositAmount ?? 0
    })

    onTimeSlotSelect()
  }

  const formatCurrency = useFormatCurrency()

  return (
    <div key={timeSlot} className='flex flex-col'>
      <Button
        variant='secondary'
        size='sm'
        className={cx('w-[100px]', {
          'ml-4 sm:ml-0': timeSlotIndex === 0,
          'mr-4 sm:mr-0': timeSlotIndex === totalTimeSlots - 1
        })}
        onClick={handleClick}
        testId={`TimeSlot-${serviceAreaGroupName}-${formattedTime}`}
      >
        {formattedTime}
      </Button>
      {depositAmount && (
        <span className='type-caption text-center mt-0.5'>
          {formatCurrency(depositAmount)} Deposit
        </span>
      )}
    </div>
  )
}

export default TimeSlotButton
